<template>
  <div class="not-found">
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
      <template #extra>
        <a-button type="primary" @click="backHome">Back Home</a-button>
      </template>
    </a-result>
  </div>
</template>

<script lang="js">
export default {
  name: 'NotFound',
  data () {
    return {

    }
  },

  methods: {
    backHome () {
      this.$router.push({ path: '/' })
    }
  }
}

</script>

<style scoped lang="scss">
html body {
  background-image: none
}
.not-found {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
